#about {
  text-align: justify;
}

#about img {
  border-radius: 50%;
}

#contact img {
  max-width: 75px;
}